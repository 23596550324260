/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { HomeQuery } from '../graphqlTypes';
import { useTranslation } from 'react-i18next';
import DonateNowButton from './DonateNowButton';
import React from 'react';
import Img from 'gatsby-image';

type Props = {
  data: HomeQuery;
};

const HomeHero: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { heroImage, heroImageMobile } = data;

  return (
    <React.Fragment>
      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ['flex-start', 'center'],
          paddingBottom: [25, 25, 37],
          paddingTop: [0, 0, 37],
          px: [28, 28, 28, 40],
          maxWidth: '1188px',
          margin: 'auto',
          backgroundColor: '#F0F5F7',
          borderRadius: [0, 0, 40],
        }}
      >
        {/* Left Side: Content */}
        <div
          sx={{
            flex: 1,
            maxWidth: ['100%', '100%', '50%'],
            mx: [0, 0, 0, 'auto'],
            fontSize: [34, 44],
            display: 'flex',
            flexDirection: 'column',
            borderRadius: [10, 10, 0],
            backgroundColor: ['white','white', '#F0F5F7'],
            py: [25,25, 0],
            px: [25,25, 0],
          }}
        >
          <div
            id="HomeHero__ContentCard"
            sx={{
              width: '100%',
              borderRadius: 14,
              bg: 'unset',
            }}
          >
            <div
              sx={{
                fontWeight: 600,
                fontSize: [34, 44],
                lineHeight: 1.2,
                mt: 0,
                mb: 0,
              }}
            >
              {t('homeHero1')}
            </div>
            <div
              sx={{
                fontFamily: 'Lato Medium',
                fontSize: [34, 44],
                lineHeight: 1.2,
              }}
            >
              {t('homeHero2')}
            </div>
            <div
              sx={{
                fontFamily: 'Lato Medium',
                fontSize: [34, 44],
                lineHeight: 1.2,
              }}
            >
              {t('homeHero3')}
            </div>
            <p
              sx={{
                mt: 12,
                fontSize: [18, 20],
                mb: 0,
                lineHeight: '28px',
              }}
            >
              {t('homeHero4')}
            </p>
            <DonateNowButton
              sx={{
                mt: 16,
                display: 'inline-block',
                cursor: 'pointer',
                borderRadius: 5,
                backgroundColor: 'primary',
                borderColor: 'primary',
                borderStyle: 'solid',
                color: '#fff',
                transition: 'color, background-color 0.3s linear',
                '&:hover': {
                  backgroundColor: '#fff',
                  color: 'primary',
                },
              }}
            />
          </div>
        </div>

        {/* Right Side: Image */}
        <div
          sx={{
            flex: 1,
            width: ['90%', '100%', '370px'],
            display: ['none', 'none', 'block'],
            mx: 'auto',
          }}
        >
          <Img
            sx={{
              borderRadius: [0, 40], 
            }}
            fluid={heroImage?.childImageSharp.fluid}
          />
        </div>
      </div>

      {/* Mobile View Image */}
      <Img
        sx={{
          display: ['block', 'block', 'none'],
        }}
        fluid={heroImageMobile?.childImageSharp.fluid}
      />
    </React.Fragment>
  );
};

export default HomeHero;

export const query = graphql`
  fragment HomeHero on Query {
    heroImage: file(relativePath: { eq: "home-hero-gaza-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroImageMobile: file(relativePath: { eq: "home-hero-gaza-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
